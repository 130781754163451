import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas'; 
import jsPDF from 'jspdf';

function ViewCertificate()
{

    const navigate = useNavigate();
    const [studentDetails,setStudentDetails] = useState([]);
    const [showCertificate, setShowCertificate] = useState(false);

    const [formData, setFormData] = useState({
        certificateNo: "",
        surname:""
      });

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    const formattedDate = `${dd}/${mm}/${yyyy}`;
    
  useEffect(() => {
    
  }, []);

  const generatePDF = () => {
    const input = document.getElementById('imageWithText');
  
    const width = input.offsetWidth;
    const height = input.offsetHeight;
    const scaleFactor = 5; // Adjust scale factor for desired clarity
  
    html2canvas(input, {
      scale: scaleFactor,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        unit: 'px',
        format: [width, height], // Set PDF dimensions to match image dimensions
      });
      pdf.addImage(imgData, 'PNG', 0, 0, width, height, undefined, 'FAST');
  
      // Ensure PDF dimensions match image dimensions
      pdf.deletePage(1); // Delete the initially created blank page
      pdf.addPage([width, height]); // Add a new page with the correct dimensions
      pdf.addImage(imgData, 'PNG', 0, 0, width, height); // Add image to the new page
  
      pdf.save('image_with_text.pdf');
      setShowCertificate(false);
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        
        const response = await axios.get(`http://localhost:5000/fetch_single_student_certificate/${formData.certificateNo}/${formData.surname}`);
        setStudentDetails(response.data);
        setFormData({
            certificateNo: "",
            surname:""
          });
          setShowCertificate(true);
         

    console.log("student : " +response.data);

    } catch (error) {
        
        console.error('Error submitting form data:', error);
        setFormData({
            certificateNo: "",
            surname:""
          });
        alert("Certificate not found");

      }
  }

    return (
        <>
<div className="content-wrapper">
<section className="content" >
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Search Certificate
              </h3>
            </div>
            <div className="card-body">
              <form className="form-inline" onSubmit={handleSubmit}>
                <div className="form-group m-2">
                  <label className='mx-2'>Certificate No: </label>
                  <input
                    name="certificateNo"
                    type="text"
                    className="form-control mx-2 form-control-sm"
                    value={formData.certificateNo}
                    onChange={handleChange} />
                    <label className='mx-2'>Student Surname </label>
                  <input
                    name="surname"
                    type="text"
                    className="form-control mx-2 form-control-sm"
                    value={formData.surname}
                    onChange={handleChange} />
                  <button type="submit" className="btn btn-primary btn-sm">Download</button>
                </div>
              </form>
            </div>
          </div>
        </section>

        {showCertificate && (
            
        <section className="content" style={{ userSelect: 'none', pointerEvents: 'none' }}>
             <div className="preloader flex-column justify-content-center align-items-center">
    <img className="animation__shake" src="/template/dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60"/>
    <h3>please wait certificate download start in 10 sec ....</h3>
  </div>
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Student Certificate
              </h3>
            </div>
            <div className="card-body">
            <div>
        <div id="imageWithText" style={{ position: 'relative', width: '500px', height: '500px' }}>
          <img src="/assets/certificates/sevent_mentor_certificate.png" alt="sevent_mentor_certificate" style={{ width: '100%', height: '100%' }} onLoad={generatePDF}/>
          <div style={{ position: 'absolute', top: '41%', left: '40%', transform: 'translate(-50%, -50%)', color: 'black', fontSize: '14px',fontWeight:'bold' }}>{studentDetails.studentFullName}</div>

          <div style={{ position: 'absolute', top: '52.5%', left: '35%', transform: 'translate(-50%, -50%)', color: 'black', fontSize: '14px',fontWeight:'bold' }}>{studentDetails.courseName}</div>

          <div style={{ position: 'absolute', top: '67%', left: '46%', transform: 'translate(-50%, -50%)', color: 'black', fontSize: '12px',fontWeight:'bold' }}>{studentDetails.courseStartDate}</div>

          <div style={{ position: 'absolute', top: '67%', left: '65%', transform: 'translate(-50%, -50%)', color: 'black', fontSize: '12px',fontWeight:'bold' }}>{studentDetails.courseEndDate}</div>

          <div style={{ position: 'absolute', top: '82.8%', left: '15%', transform: 'translate(-50%, -50%)', color: 'black', fontSize: '12px',fontWeight:'bold' }}>{formattedDate}</div>
        </div>
        {/* <button onClick={generatePDF}>Generate PDF</button> */}
      </div>
            </div>
          </div>
        </section>
        )}
        </div>


    

        </>
    )
}
export default ViewCertificate;