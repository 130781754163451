import ReactDOM from "react-dom";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Routes,
  NavLink,
} from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas"; // Library to capture DOM elements
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

function AllCertificates() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [certificateRecords, setCertificateRecords] = useState([]);
  const tableRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file");
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false }); // Include raw numbers

      // Process date values to convert Excel serial numbers to human-readable dates
      jsonData = jsonData.map((item) => {
        return {
          ...item,
          entryDate: formatDate(item.entryDate),
          courseStartDate: formatDate(item.courseStartDate),
          courseEndDate: formatDate(item.courseEndDate),
        };
      });

      console.log("Data to be sent:", jsonData);

      try {
        // Assuming you have an API endpoint to handle MongoDB insertion
        const response = await axios.post(
          "http://localhost:5000/add_certificate_records",
          { data: jsonData }
        );
        console.log(response.data);
        alert("Data uploaded successfully!");
      } catch (error) {
        console.error("Error uploading data:", error);
        alert("An error occurred while uploading data.");
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  const formatDate = (dateString) => {
    if (
      typeof dateString === "string" &&
      dateString.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)
    ) {
      const [month, day, year] = dateString.split("/");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    }
    return dateString; // Return as is if not in the expected format
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:5000/fetch_certificate_records"
        );
        setCertificateRecords(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      certificateRecords.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      const dataWithSerialNumbers = certificateRecords.map(
        (department, index) => ({
          sr: index + 1,
          ...department,
        })
      );
      $(tableRef.current).DataTable({
        data: dataWithSerialNumbers,
        columns: [
          { title: "sr", data: "sr" },
          { title: "Student Name", data: "studentFullName" },
          { title: "Course", data: "courseName" },
          { title: "Start Date", data: "courseStartDate" },
          { title: "End Date", data: "courseEndDate" },
          { title: "Trainer", data: "trainerName" },
          { title: "Status", data: "certificateStatus" },
          { title: "Actions", orderable: false, searchable: false }, // Exclude Actions from ordering and searching
        ],
        columnDefs: [
          {
            targets: -1, // Target the last column (Actions)
            render: function (data, type, row, meta) {
              return `
                <button class="btn btn-primary btn-sm" onClick="viewCertificate('${row._id}')">Certificate</button>                
              `;
            },
          },
        ],
      });
    }
  }, [certificateRecords]);

  window.viewCertificate = async (id) => {
    navigate(`/viewCertificate/${id}`);
  };

  const generatePDF = () => {
    const input = document.getElementById("imageWithText");

    const width = input.offsetWidth;
    const height = input.offsetHeight;
    const scaleFactor = 5; // Adjust scale factor for desired clarity

    html2canvas(input, {
      scale: scaleFactor,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        unit: "px",
        format: [width, height], // Set PDF dimensions to match image dimensions
      });
      pdf.addImage(imgData, "PNG", 0, 0, width, height, undefined, "FAST");

      // Ensure PDF dimensions match image dimensions
      pdf.deletePage(1); // Delete the initially created blank page
      pdf.addPage([width, height]); // Add a new page with the correct dimensions
      pdf.addImage(imgData, "PNG", 0, 0, width, height); // Add image to the new page

      pdf.save("image_with_text.pdf");
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Certificates
              </h3>
            </div>
            <div className="card-body">
              <div>
                <input type="file" accept=".xlsx" onChange={handleFileChange} />
                <button onClick={handleUpload}>Upload Data</button>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                All Certificates
              </h3>
            </div>
            <div className="card-body">
              <table
                ref={tableRef}
                className="table table-responsive table-bordered"
              >
                <thead className="bg-info">
                  <tr>
                    <th className="col-1">sr</th>
                    <th className="col-2">Student Name</th>
                    <th className="col-1">Course</th>
                    <th className="col-1">Start Date</th>
                    <th className="col-1">End Date</th>
                    <th className="col-1">Trainer</th>
                    <th className="col-1">Status</th>
                    <th className="col-1">Action</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
}
export default AllCertificates;
