import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function EditStudent()
{

    const [studentData, setStudentData] = useState({});
  const  id  = useParams();

  const [formData, setFormData] = useState({ ...studentData });

  const [courses, setCourses] = useState([]);

  const [selectedCourses, setSelectedCourses] = useState(
    studentData.courses ? studentData.courses.map(course => ({ value: course._id, label: course.course_name })) : []
  );
  
  const [totalFees, setTotalFees] = useState(studentData.courseFees);


  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/fetch_single_student/${id.studentId}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };
  
    if (id) {
      fetchStudentData();
    }
  }, [id]);
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCourseChange = (selectedOptions) => {
    setSelectedCourses(selectedOptions || []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 'calc(1.8125rem + 2px)',
      minHeight: 'calc(1.8125rem + 2px)',
      borderRadius: '0.2rem',
      border: '1px solid #ced4da',
    }),
  };

    return(
        <>
        <div className="content-wrapper">
        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Edit Student
              </h3>
            </div>
            <div className="card-body">
              <div className='row d-flex'>
              <form onSubmit={handleSubmit}>
              <div className='row my-2'> 
              <div className='col-12 bg-info'>
              <h5 className='text-center'>PERSONAL DETAILS</h5>
              </div>
              
              </div>
                <div className='row'> 
                <div className="form-group col-3">
                    <label>Student Name</label>
                    <input type="text" className="form-control form-control-sm" name="studentName" value={formData.studentName} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Email</label>
                    <input type="email" className="form-control form-control-sm" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Mobile Number</label>
                    <input type="text" className="form-control form-control-sm" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Date of Birth</label>
                    <input type="date" className="form-control form-control-sm" name="dob" value={formData.dob} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Address</label>
                    <textarea className="form-control form-control-sm" rows={3} name="address" value={formData.address} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Adharcard Number</label>
                    <input type="text" className="form-control form-control-sm" name="adharcardNumber" value={formData.adharcardNumber} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Gender</label><br />
                    <div className="form-check form-check-inline">
                        <input className="form-check-input form-check-input-sm" type="radio" name="gender" value="male" onChange={handleChange} checked/>
                        <label className="form-check-label">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input form-check-input-sm" type="radio" name="gender" value="female" onChange={handleChange} />
                        <label className="form-check-label">Female</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input form-check-input" type="radio" name="gender" value="other" onChange={handleChange} />
                        <label className="form-check-label">Other</label>
                    </div>
                </div>
                <div className="form-group col-3">
                    <label>Upload Photo</label>
                    <input type="file" className="form-control-file form-control-sm" name="photo" onChange={handleChange} />
                </div>
                </div>
                <div className='row my-2'> 
              <div className='col-12 bg-info'>
              <h5 className='text-center'>EDUCATIONAL DETAILS</h5>
              </div>
              
              </div>
                <div className='row'>
                <div className="form-group col-3">
                    <label>Degree</label>
                    <input type="text" className="form-control form-control-sm" name="degree" value={formData.degree} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Faculty</label>
                    <select className="form-control form-control-sm" name="faculty" value={formData.faculty} onChange={handleChange}>
                        <option value="">Select Faculty</option>
                        <option value="Test">Test Faculty</option>
                       
                    </select>
                </div>
                <div className="form-group col-3">
                    <label>Passing Year</label>
                    <input type="number" className="form-control form-control-sm" name="passingYear" value={formData.passingYear} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>College Name</label>
                    <input type="text" className="form-control form-control-sm" name="collegeName" value={formData.collegeName} onChange={handleChange} />
                </div>
                </div>
                <div className='row my-2'> 
              <div className='col-12 bg-info'>
              <h5 className='text-center'>COURSE DETAILS</h5>
              </div>
              
              </div>
                <div className='row'>
                <div className="form-group col-3">
                    <label>Course Name</label>
                    <Select 
                                            options={courses.map(course => ({ value: course._id, label: course.course_name }))}
                                            isMulti
                                            onChange={handleCourseChange}
                                            value={selectedCourses}
                                            styles={customStyles}
                                        />
                    
                </div>
                <div className="form-group col-3">
                    <label>Course Fees</label>
                    <input type="text" className="form-control form-control-sm" name="courseFees" value={totalFees} onChange={handleChange} />
                </div>
                <div className="form-group col-3">
                    <label>Joining Date</label>
                    <input type="date" className="form-control form-control-sm" name="joiningDate" value={studentData.joiningDate} onChange={handleChange} />
                </div>
                </div>
                <div className='row d-flex justify-content-center'>
                <button type="submit" className="btn btn-success">Update</button>

                </div>
                
            </form>
            </div>
            </div>
          </div>
        </section>
        </div>
        </>
    )

}
export default EditStudent;