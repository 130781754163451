import ReactDOM from 'react-dom';  
import { Route, Link, BrowserRouter as Router, Routes, NavLink } from 'react-router-dom'  
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

function AllStudents()
{
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchStudents = async () => {
        try {
            const response = await axios.get('http://localhost:5000/fetch_students');
            setStudents(response.data);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    fetchStudents();
}, []);

useEffect(() => {
  if (students.length > 0 && tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
    const fetchData = async () => {
      
      const coursesMap = {};
      try {
        const coursesResponse = await axios.get('http://localhost:5000/fetch_courses');
        coursesResponse.data.forEach(course => {
          coursesMap[course._id] = course.course_name;
        });
      } catch (error) {
        console.error('Error fetching courses:', error);
      }

     
      const dataWithFormattedDates = students.map((student, index) => ({
        sr: index + 1,
        ...student,
        courses: student.courses.map(courseId => coursesMap[courseId]).join(', '), 
        joiningDate: new Date(student.joiningDate).toLocaleDateString('en-GB'), 
      }));

      
      const dataTable = $(tableRef.current).DataTable({
        data: dataWithFormattedDates,
        columns: [
          { title: 'sr', data: 'sr' },
          { title: 'Name', data: 'studentName' },
          { title: 'Mobile', data: 'mobileNumber' },
          { title: 'Courses', data: 'courses' },
          { title: 'Joining', data: 'joiningDate' },
          { title: 'Actions', orderable: false, searchable: false } 
        ],
        columnDefs: [
          {
            targets: -1, 
            render: function(data, type, row, meta) {
              return `
                <button class="btn btn-primary btn-sm" onClick="editStudent('${row._id}')">Edit</button>
                <button class="btn btn-danger btn-sm" onClick="deleteStudent('${row._id}')">Del</button>
                <button class="btn btn-info btn-sm" onClick="viewStudent('${row._id}')">View</button>
                <button class="btn btn-warning btn-sm" onClick="blockStudent('${row._id}')">Block</button>
              `;
            }
          }
        ]
      });

     
      tableRef.current = dataTable;
    };

    fetchData();
  }
}, [students]);



window.editStudent = async (id) => {
  navigate(`/edit_student/${id}`);
};

window.deleteStudent = async (id) => {
  try {
    await axios.delete(`http://localhost:5000/delete_student/${id}`);
    const updatedStudents = students.filter(student => student._id !== id);
    setStudents(updatedStudents);
    console.log('Student deleted successfully');

    const updatedData = updatedStudents.map((student, index) => ({
      sr: index + 1,
      ...student
    }));

    // Redraw the DataTable with the updated data
    if (tableRef.current) {
      const dataTable = $(tableRef.current).DataTable();
      dataTable.clear().rows.add(updatedData).draw();
    }
  } catch (error) {
    console.error('Error deleting student:', error);
  }
};


window.viewStudent = async (id) => {
};

window.blockStudent = async (id) => {
};



    return(
        <>
        <div className="content-wrapper">
        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                All Students
              </h3>
              <div className='d-flex justify-content-end'>
                <Link className="btn btn-primary btn-sm" to="/add_student" >Add Student</Link>
              </div>
            </div>
            <div className="card-body">
              <table ref={tableRef} className='table-bordered'>
                <thead className='bg-info'>
                  <tr>
                    <th className='col-1'>Sr. No</th>
                    <th className='col-2'>Name</th>
                    <th className='col-2'>Mobile</th>
                    <th className='col-3'>Courses</th>
                    <th className='col-2'>Joining</th>
                    <th className='col-2'>Actions</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </section>
        </div>
        </>
    );

}
export default AllStudents;