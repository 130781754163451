import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function VerifyCertificate() {
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState([]);

  const [formData, setFormData] = useState({
    certificateNo: "",
    surname: "",
  });

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();

  const formattedDate = `${dd}/${mm}/${yyyy}`;

  useEffect(() => {}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `http://localhost:5000/fetch_single_student_certificate/${formData.certificateNo}/${formData.surname}`
      );
      setStudentDetails(response.data);
      setFormData({
        certificateNo: "",
        surname: "",
      });
      Swal.fire({
        title: "",
        html: `
          <table className="table" style="text-align: left">
            <tr>
              <th>Status </th>
              <td style="color:green;font-weight: bold;">Verified and it's valid certificate.</td>
            </tr>
            <tr>
              <th>Certificate No: </th>
              <td>${response.data.certificateNo}</td>
            </tr>
            <tr>
              <th>Student Name: </th>
              <td>${response.data.studentFullName}</td>
            </tr>
            <tr>
              <th>Course: </th>
              <td>${response.data.courseName}</td>
            </tr>
            <tr>
              <th>Batch Start Date: </th>
              <td>${response.data.courseStartDate}</td>
            </tr>
            <tr>
              <th>Batch End Date: </th>
              <td>${response.data.courseEndDate}</td>
            </tr>
          </table>
        `,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      setFormData({
        certificateNo: "",
        surname: "",
      });
      Swal.fire({
        title: "Invalid",
        text: "Certificate is not valid, please check entered data.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="container-fluid forget-password">
        <div className="row mt-5">
          <div className="col-4 offset-4">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="text-center">
                  <img
                    className="mb-4 mt-4"
                    src="/assets/certificates/sevenmentor-logos.jpg"
                    alt="car-key"
                    border="0"
                  />
                  <h2 className="text-center">Verify Certificate</h2>
                  <form
                    id="verification-form"
                    role="form"
                    className="form verificationForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="glyphicon glyphicon-envelope color-blue"></i>
                        </span>
                        <input
                          id="forgetAnswer"
                          value={formData.certificateNo}
                          onChange={handleChange}
                          name="certificateNo"
                          placeholder="Certificate No: "
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="glyphicon glyphicon-envelope color-blue"></i>
                        </span>
                        <input
                          id="forgetAnswer"
                          name="surname"
                          value={formData.surname}
                          onChange={handleChange}
                          placeholder="Student Surname"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        name="btnVerify"
                        className="btn btn-lg btn-primary btn-block btnVerify"
                        value="Verify"
                        type="submit"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VerifyCertificate;
