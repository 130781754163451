import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';

function Departments() {
  const [formData, setFormData] = useState({
    department_name: ""
  });
  const [editingDepartmentId, setEditingDepartmentId] = useState(null);
  
  const [departments, setDepartments] = useState([]);
  const tableRef = useRef(null);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/fetch_departments');
        setDepartments(response.data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchData();
  }, []); // Fetch departments on component mount

  useEffect(() => {
    if (departments.length > 0 && tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
      const dataWithSerialNumbers = departments.map((department, index) => ({
        sr: index + 1,
        ...department
      }));
      $(tableRef.current).DataTable({
        data: dataWithSerialNumbers,
        columns: [
          { title: 'sr', data: 'sr' },
          { title: 'Name', data: 'department_name' },
          { title: 'Actions', orderable: false, searchable: false } // Exclude Actions from ordering and searching
        ],
        columnDefs: [
          {
            targets: -1, // Target the last column (Actions)
            render: function(data, type, row, meta) {
              return `
                <button class="btn btn-primary btn-sm" onClick="editDepartment('${row._id}')">Edit</button>
                <button class="btn btn-danger btn-sm" onClick="deleteDepartment('${row._id}')">Delete</button>
              `;
            }
          }
        ]
      });
    }
  }, [departments]);

  window.editDepartment = async (id) => {
    const departmentToEdit = departments.find(department => department._id === id);
    setFormData({
      department_name: departmentToEdit.department_name
    });
    setEditingDepartmentId(id);

  };

  window.deleteDepartment = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/delete_department/${id}`);
      const updatedDepartments = departments.filter(department => department._id !== id);
      setDepartments(updatedDepartments);
      console.log('Department deleted successfully');

      const updatedData = updatedDepartments.map((department, index) => ({
        sr: index + 1,
        ...department
      }));
  
      // Redraw the DataTable with the updated data
      if (tableRef.current) {
        $(tableRef.current).DataTable().clear().rows.add(updatedData).draw();
      }
    } catch (error) {
      console.error('Error deleting department:', error);
    }
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingDepartmentId) {
        await axios.put(`http://localhost:5000/update_department/${editingDepartmentId}`, formData);
      } else {
        await axios.post('http://localhost:5000/add_department', formData);
      }
      console.log('Form data submitted successfully');
      const response = await axios.get('http://localhost:5000/fetch_departments');
      setDepartments(response.data);
      setFormData({ department_name: "" });
      setEditingDepartmentId(null);
      if (tableRef.current) {
        $(tableRef.current).DataTable().clear().rows.add(response.data).draw();
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                {editingDepartmentId ? 'Edit Department' : 'Add Department'}
              </h3>
            </div>
            <div className="card-body">
              <form className="form-inline" onSubmit={handleSubmit}>
                <div className="form-group m-2">
                  <label className='mx-2'>Department Name: </label>
                  <input
                    name="department_name"
                    type="text"
                    className="form-control mx-2 form-control-sm"
                    value={formData.department_name}
                    onChange={handleChange} />
                  <button type="submit" className="btn btn-primary btn-sm">{editingDepartmentId ? 'Update' : 'Submit'}</button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Departments
              </h3>
            </div>
            <div className="card-body">
              <table ref={tableRef} className='table-bordered'>
                <thead className='bg-info'>
                  <tr>
                    <th className='col-1'>Sr. No</th>
                    <th className='col-9'>Name</th>
                    <th className='col-2'>Actions</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Departments;
