import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';

function Courses()
{

    const [formData, setFormData] = useState({
        course_name: "",
        course_code:"",
        course_description:"",
        course_fees:"",
        department:"",
        course_duration:""
      });

      const [editingCourseId, setEditingCourseId] = useState(null);

      const [courses, setCourses] = useState([]);

      const [departments, setDepartments] = useState([]);

      const tableRef = useRef(null);

      useEffect(() => {
        const fetchDepartments = async () => {
          try {
            const response = await axios.get('http://localhost:5000/fetch_departments');
            setDepartments(response.data);
          } catch (error) {
            console.error('Error fetching departments:', error);
          }
        };
        fetchDepartments();

        const fetchData = async () => {
            try {
              const response = await axios.get('http://localhost:5000/fetch_courses');
              setCourses(response.data);
            } catch (error) {
              console.error('Error fetching courses:', error);
            }
          };
      
          fetchData();
      }, []);

      useEffect(() => {
        if (courses.length > 0 && tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
          const dataWithSerialNumbers = courses.map((course, index) => {
            const department = departments.find(dep => dep._id === course.department);
            return {
              sr: index + 1,
              course_name: course.course_name,
              course_code: course.course_code,
              course_description: course.course_description,
              course_fees: course.course_fees,
              department: department ? department.department_name : '', 
              course_duration: course.course_duration,
              _id: course._id 
            };
          });
          $(tableRef.current).DataTable({
            data: dataWithSerialNumbers,
            columns: [
              { title: 'sr', data: 'sr' },
              { title: 'Name', data: 'course_name' },
              { title: 'Code', data: 'course_code' },
              { title: 'Description', data: 'course_description' },
              { title: 'Fees', data: 'course_fees' },
              { title: 'Department', data: 'department' },
              { title: 'Duration', data: 'course_duration' },
              { title: 'Actions', orderable: false, searchable: false } 
            ],
            columnDefs: [
              {
                targets: -1, 
                render: function(data, type, row, meta) {
                  return `
                    <button class="btn btn-primary btn-sm" onClick="editCourse('${row._id}')">Edit</button>
                    <button class="btn btn-danger btn-sm" onClick="deleteCourse('${row._id}')">Delete</button>
                  `;
                }
              }
            ]
          });
        }
      }, [courses, departments]);
      
      useEffect(() => {
        fetchUpdatedData();
      }, [courses, departments]);
      
      const fetchUpdatedData = async () => {
        try {
          
          const response1 = await axios.get('http://localhost:5000/fetch_departments');
          const updatedDepartments = response1.data;
          
          const response2 = await axios.get('http://localhost:5000/fetch_courses');
          const updatedCourses = response2.data;
      
          
          const dataWithSerialNumbers = updatedCourses.map((course, index) => {
            const department = updatedDepartments.find(dep => dep._id === course.department);
            return {
              sr: index + 1,
              course_name: course.course_name,
              course_code: course.course_code,
              course_description: course.course_description,
              course_fees: course.course_fees,
              department: department ? department.department_name : '', 
              course_duration: course.course_duration,
              _id: course._id 
            };
          });
      
          
          if (tableRef.current) {
            $(tableRef.current).DataTable().clear().rows.add(dataWithSerialNumbers).draw();
          }
        } catch (error) {
          console.error('Error fetching updated data:', error);
        }
      };
      
      window.editCourse = async (id) => {
        const courseToEdit = courses.find(course => course._id === id);
        setFormData(courseToEdit);
        setEditingCourseId(id);
      };
      
      window.deleteCourse = async (id) => {
        try {
          await axios.delete(`http://localhost:5000/delete_course/${id}`);
          const updatedCourses = courses.filter(course => course._id !== id);
          setCourses(updatedCourses);
          console.log('Course deleted successfully');
          fetchUpdatedData();
         
        } catch (error) {
          console.error('Error deleting course:', error);
        }
      };

      

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleSubmit = async (e) => {
        console.log("submit");
        e.preventDefault();
        try {
          if (editingCourseId) {
            await axios.put(`http://localhost:5000/update_course/${editingCourseId}`, formData);
            console.log('Course updated successfully');
          } else {
            await axios.post('http://localhost:5000/add_course', formData);
            console.log('Course added successfully');
          }
          
          setFormData({
            course_name: "",
            course_code: "",
            course_description: "",
            course_fees: "",
            department: "",
            course_duration: ""
          });
          setEditingCourseId(null);
      
          
          fetchUpdatedData();
        } catch (error) {
          console.error('Error adding/updating course:', error);
        }
      };
      
      
    return(
        <>
        <div className="content-wrapper">
        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                {editingCourseId ? 'Edit Course' : 'Add Course'}
              </h3>
            </div>
            <div className="card-body">
              <form className="" onSubmit={handleSubmit}>
                <div className='row d-flex justify-content-center'>
                   
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Course Name: </label>
                  <input
                    name="course_name"
                    type="text"
                    className="form-control mx-2 form-control-sm "
                    value={formData.course_name}
                    onChange={handleChange} />
                  
                </div>
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Course Code: </label>
                  <input
                    name="course_code"
                    type="text"
                    className="form-control mx-2 form-control-sm "
                    value={formData.course_code}
                    onChange={handleChange} />
                </div>
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Course Description: </label>
                  <input
                    name="course_description"
                    type="text"
                    className="form-control mx-2 form-control-sm "
                    value={formData.course_description}
                    onChange={handleChange} />
                </div>
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Course fees: </label>
                  <input
                    name="course_fees"
                    type="text"
                    className="form-control mx-2 form-control-sm "
                    value={formData.course_fees}
                    onChange={handleChange} />
                </div>
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Department: </label>
                  <select
                    name="department"
                    className="form-control mx-2 form-control-sm"
                    value={formData.department}
                    onChange={handleChange}>
                    <option value="">Select Department</option>
                    {departments.map(department => (
                      <option key={department._id} value={department._id}>
                        {department.department_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group m-2 col-3">
                  <label className='mx-2 '>Course Duration: </label>
                  <input
                    name="course_duration"
                    type="text"
                    className="form-control mx-2 form-control-sm "
                    value={formData.course_duration}
                    onChange={handleChange} />
                </div>
                <div className="form-group m-2 col-12 text-center">
                 
                <button type="submit" className="btn btn-primary btn-sm">{editingCourseId ? 'Update' : 'Submit'}</button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card m-3">
            <div className="card-header bg-warning">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Courses
              </h3>
            </div>
            <div className="card-body">
              <table ref={tableRef} className='table-bordered'>
                <thead className='bg-info'>
                  <tr>
                    <th className=''>Sr. No</th>
                    <th className=''>Course Name</th>
                    <th className=''>Course Code</th>
                    <th className=''>Course Description</th>
                    <th className=''>Course fees</th>
                    <th className=''>Department</th>
                    <th className=''>Course Duration</th>
                    <th className=''>Actions</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </section>
        </div>
        </>
    )
}
export default Courses;