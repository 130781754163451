import React from 'react';
import { Routes } from './routes';
function App() {
  return (
    <>
      <Routes isAuthorized={true} isPublic={true}/>
    </>
  );
}

export default App;
