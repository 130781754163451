// Layouts
import AnonymousLayout from "../layouts/anonymous-layout";
import MainLayout from "../layouts/main-layout";

import Dashboard from '../components/dashboard/Dashboard';

import Departments from '../components/departments/Departments';
import Courses from '../components/courses/Courses';
import AllStudents from '../components/student/AllStudents';
import AddStudent from '../components/student/AddStudent';
import EditStudent from '../components/student/EditStudent';
import AllCertificates from '../components/certificates/AllCertificates';
import InsertTempCertificateRecords from '../components/certificates/InsertTempCertificateRecords';
import ViewCertificate from '../components/certificates/ViewCertificate';
import VerifyCertificate from '../components/certificates/VerifyCertificate';
import { renderRoutes } from "./generate-routes";



export const routes = [
    {
        layout: AnonymousLayout,
        routes: [
            {
                name: 'verify_certificate',
                title: 'Verify Certificate',
                component: VerifyCertificate,
                path: '/',
                isPublic: true
            },
            {
                name: 'verify_certificate',
                title: 'Verify Certificate',
                component: VerifyCertificate,
                path: '/verify_certificate',
                isPublic: true
            },
        ]
    },
    {
        layout: MainLayout,
        routes: [
            {
                name: 'dashboard',
                title: 'Dashboard',
                component: Dashboard,
                path: '/dashboard',
                isPublic: true
            },
            {
                name: 'departments',
                title: 'Departments',
                component: Departments,
                path: '/departments',
                isPublic: true
            },
            {
                name: 'courses',
                title: 'Courses',
                component: Courses,
                path: '/courses',
                isPublic: true
            },
            {
                name: 'all_students',
                title: 'All Students',
                component: AllStudents,
                path: '/all_students',
                isPublic: true
            },
            {
                name: 'add_students',
                title: 'Add students',
                component: AddStudent,
                path: '/add_students',
                isPublic: true
            },
            {
                name: 'Edit_Student',
                title: 'Edit students',
                component: EditStudent,
                path: '/edit_student/:studentId',
                isPublic: true
            },
            {
                name: 'all_certificates',
                title: 'All Certificates',
                component: AllCertificates,
                path: '/all_certificates',
                isPublic: true
            },
            {
                name: 'insert_temp_certificate_records',
                title: 'insert_temp_certificate_records',
                component: InsertTempCertificateRecords,
                path: '/insert_temp_certificate_records',
                isPublic: true
            },
            {
                name: 'ViewCertificate',
                title: 'View Certificate',
                component: ViewCertificate,
                path: '/viewCertificate',
                isPublic: true
            },
            //   {
            //     name: 'users',
            //     title: 'Users',
            //     hasSiderLink: true,
            //     routes: [
            //       {
            //         name: 'list-users',
            //         title: 'List of users',
            //         hasSiderLink: true,
            //         component: ListUsers,
            //         path: '/users'
            //       },
            //       {
            //         name: 'create-user',
            //         title: 'Add user',
            //         hasSiderLink: true,
            //         component: CreateUser,
            //         path: '/users/new'
            //       }
            //     ]
            //   }
        ]
    }
];
export const Routes = renderRoutes(routes);