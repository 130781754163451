function Footer()
{
    return(
        <>
         
<footer className="main-footer">
  <strong>Copyright © 2014-2021 <a href="#">Seven Mentor Pvt Ltd</a>.</strong>
  All rights reserved.
  <div className="float-right d-none d-sm-inline-block">
    <b>Version</b> 1.0.0
  </div>
</footer>

        </>
    );
}
export default Footer;